@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #f3f4f6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fixed-navbar {
  position: relative;
  height: 100%;
  /* width: 250px; */
  position: fixed;
  overflow-x: hidden;
  z-index: 99;
}

.fixed-navbar-settings {
  position: relative;
  height: 100%;
  margin-left: 56px;
  /* width: 250px; */
  position: fixed;
  overflow-x: hidden;
  z-index: 99;
}

nav {
  position: fixed !important;
  overflow: hidden;
  width: 100%;
}

.highcharts-yaxis {
  display: none !important;
}

.arrow-sub-menu {
  transform: rotate(180deg);
}

.react-datepicker__input-container {
  border-color: #d1d5db;
  padding-left: 8px;
  border-width: 1px;
  width: 100% !important;
  border-radius: 9px;
  padding: 8px
}

.cls-1,
.cls-2,
.cls-3 {
  fill: none;
  stroke: white;
}

.cls-4 {
  stroke: white;
  strokeWidth: 25px;
}

.cls-5 {
  stroke: white;
  strokeWidth: 20px;
}

.cls-1 {
  stroke-miterlimit: 10;
}

.cls-1,
.cls-2 {
  strokeWidth: 25px;
}

.cls-2,
.cls-3 {
  strokeLinecap: round;
  strokeLinejoin: round;
}

.cls-3 {
  strokeWidth: 23px;
}

.no-scroll {
  height: 100%;
  overflow: hidden!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.input-icons i {
  position: absolute;
}

.iconFa {
  padding: 11px;
  margin-top: 10px;
  color: #9ca3af
}

.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
}

.mydiv {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000 !important;
  width: 100%;
}

.mydropdown {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1 !important;
  width: 100%;
  height: 100%;
}

.myModal {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000 !important;
  width: 100%;
  height: 100%;

  backdrop-filter: blur(3px);
}

.center-absolutely {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  z-index: 1200 !important;


  box-shadow: 4px 4px 64px 0px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 4px 4px 64px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 4px 4px 64px 0px rgba(0, 0, 0, 0.14);
}

.center-absolutely2 {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1200 !important;


  box-shadow: 4px 4px 64px 0px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 4px 4px 64px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 4px 4px 64px 0px rgba(0, 0, 0, 0.14);
}

.btn-checkbox {
  background-color: #e5e7eb;
  border-radius: 4px;
  margin-right: 4px;
  border: 1px solid #fff;
  overflow: hidden;
  float: left;
}

.btn-checkbox label span {
  text-align: center;
  display: block;
  padding: 7px;
}

.btn-checkbox label input {
  position: absolute;
  display: none;
  color: #fff !important;
}

.btn-checkbox label input+span {
  color: #1f2937;
}


.btn-checkbox input:checked+span {
  color: #ffffff;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
}

input:checked+span {
  background-color: #655fd3;
}

.containerPaginate {
  display: flex;
  flex: row;
  justify-content: end;
}

.break {
  margin-right: 4px;
}

.previousBtnPaginate {
  width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 20%;
  font-size: 19px;
  color: #fff;
  text-align: center;
  background: transparent;
  color: #5B29EA;
  border: 1px solid #bdc3c7;
  margin-right: 6px;
  cursor: pointer;
}

.nextBtnPaginate {
  width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 20%;
  font-size: 19px;
  color: #fff;
  text-align: center;
  background: transparent;
  color: #5B29EA;
  border: 1px solid #bdc3c7;
  margin-left: 6px;
  cursor: pointer;
}

.activeBtnPaginate {
  background-color: #5B29EA !important;
  cursor: pointer;
  color: white !important;
  border: 0px !important;
  margin-left: 3px;
  ;
  cursor: pointer;
}

.pageBtnPaginate {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 20%;
  font-size: 17px;
  color: #fff;
  text-align: center;
  background: transparent;
  color: #5B29EA;
  border: 1px solid #bdc3c7;
  margin-right: 3px;
  cursor: pointer;
}

.leaflet-container {
  width: 31vh;
  height: 16vh;
}

.one {
  position: relative;
  margin: 50px auto;
  width: 200px;
  height: 100px;
  background: #900;
}

.two {
  position: absolute;
  top: 50%;
  /* this to center the box w.r.t to parent */
  left: 50%;
  /* this to center the box w.r.t to parent */
  /* Above 2 lines wont fully center it, the next line internally centers it, buy moving itself 50% left and top */
  transform: translate(-50%, -50%);
  margin: auto;
  background: #0f0;
  opacity: 0.3;
  height: 160px;
  width: 400px;
}

.integrationTitle {
  font-size: 23px !important;
  font-weight: 500;
}

.integrationBlock {
  margin-bottom: 20px;
}

.integrationArray {
  list-style-type: disc;
  margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rdrDateRangePickerWrapper {
  display: flex !important;
  width: 100% !important;
}

.rdrDefinedRangesWrapper {
  width: 20% !important
}


.rdrCalendarWrapper {
  width: 100% !important
}

.rdrDateRangeWrapper {
  width: 100% !important
}

.rdrMonth {
  width: 100% !important
}

.time-line-ctnr .time-line>.time-label>span {
  background-color: #4338ca !important;
  border-radius: 4px !important;
}

.time-line-ctnr .time-line>li>.fa {
  position: absolute;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  color: #4338ca !important;
  background: #ecf0f1;
  left: 28px;
  top: 9px;
  border: 2px solid #4338ca !important;
  display: inline-block;
  box-sizing: border-box;
}

.time-line-ctnr .time-line:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: -10px;
  width: 2px;
  background: #4338ca !important;
  left: 32px;
  border-radius: 2px;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 2px !important
}

.time-line-ctnr .time-line {
  position: relative;
  margin: 0 0 0px 0;
  padding: 0;
  list-style: none;
}

.time-line-ctnr .time-line:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: -10px;
  width: 2px;
  background: #3498db;
  left: 32px;
  border-radius: 2px;
}

.time-line-ctnr .time-line>.time-label>span {
  font-weight: 600;
  padding: 5px 15px;
  display: inline-block;
  background-color: #e74c3c;
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
}

.time-line-ctnr .time-line>li {
  position: relative;
  margin-bottom: 10px;
  margin-right: 0;
}

.time-line-ctnr .time-line>li>.time-line-item {
  border-radius: 3px;
  background: #fff;
  color: #444;
  margin-right: 15px;
  position: relative;
  margin-left: 50px;
}

.time-line-ctnr .time-line>li>.time-line-item>.time-line-header, .time-line-ctnr .time-line>li>.time-line-item .time {
  font-size: 14px;
  padding: 6px 10px;
}

.time-line-ctnr .time-line>li>.time-line-item .time {
  font-size: 12px;
  color: #999;
  float: right;
}

.time-line-ctnr .time-line>li>.time-line-item .time-line-header {
  margin: 0;
  color: #555;
  border: 1px solid #f4f4f4;
  line-height: 2;
  border-radius: 4px;
}

.time-line-ctnr .time-line>li>.fa {
  position: absolute;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  color: #666;
  background: #ecf0f1;
  left: 28px;
  top: 9px;
  border: 2px solid #3498db;
  display: inline-block;
  box-sizing: border-box;
}


.time-line-ctnr .time-line>li>.time-line-item .time-line-header {
  margin: 0 !important;
}

.notification-message {
  width: 100% !important
}

.fa-save {
  margin-top: 5px !important;
}

.custom-checkbox {
  display: none;
}

.custom-checkbox+label:before {
  font-family: FontAwesome;
  display: inline-block;
  font-size: 23px;
  cursor: pointer;
}

.custom-checkbox+.label:before {
  cursor: pointer;
}

.custom-checkbox+label:before {
  content: "\f1db";
  color: #aaaaaa;
}

/* unchecked icon */

.custom-checkbox+label:before {
  letter-spacing: 7px;
  position: relative;
  top: 2px;
}

/* space between checkbox and label */

.custom-checkbox:checked+label:before {
  content: "\f058";
  color: #69c696;
}

/* checked icon */

.placeholder {
  position: relative;
  display: inline-block;
}

.placeholder::after {
  position: absolute;
  right: 15px;
  top: 24px;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: .6;
}

.placeholder2 {
  position: relative;
  display: inline-block;
}

.placeholder2::after {
  position: absolute;
  right: 8px;
  top: 17px;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: .6;
}

.placeholder3 {
  position: relative;
  display: inline-block;
}

.placeholder3::after {
  position: absolute;
  right: 8px;
  top: 20px;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: .6;
}

.report-box {
  position: relative
}

.report-box:before {
  content: "";
  width: 96%;
  box-shadow: 0 3px 5px #0000000b;
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: .75rem;
  height: 100%;
  border-radius: .5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-slate-50) / var(--tw-bg-opacity))
}

.dark .report-box:before {
  border-color: rgb(var(--color-darkmode-500) / .6);
  background-color: rgb(var(--color-darkmode-600) / .7)
}

.report-box .report-box__item {
  position: relative
}

@media (min-width: 1279px) {
  .report-box .report-box__item:not(:last-child):before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: -7px;
    margin-top: -25px;
    height: 12px;
    width: 13px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-200) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child):before {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-500) / var(--tw-bg-opacity))
  }

  .report-box .report-box__item:not(:last-child):after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: -6px;
    margin-top: -28px;
    height: 14px;
    width: 11px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child):after {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-700) / var(--tw-bg-opacity))
  }

  .report-box .report-box__item:not(:last-child) .report-box__content:before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-right: -7px;
    margin-bottom: -25px;
    height: 12px;
    width: 13px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-200) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child) .report-box__content:before {
    background-color: rgb(var(--color-darkmode-700) / .6)
  }

  .report-box .report-box__item:not(:last-child) .report-box__content:after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-right: -6px;
    margin-bottom: -28px;
    height: 14px;
    width: 11px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-50) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child) .report-box__content:after {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity))
  }
}

.report-box .report-box__item .report-box__item__icon {
  width: 2.2rem;
  height: 2.2rem
}

.report-box .report-box__item .report-box__item__icon svg {
  width: 1.3rem;
  height: 1.3rem
}

.report-box .report-box__item .report-box__item__indicator {
  display: flex;
  align-items: center;
  padding-left: .5rem
}

.report-box-2 {
  position: relative
}

.report-box-2:before {
  content: "";
  width: 90%;
  box-shadow: 0 3px 5px #0000000b;
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: .75rem;
  height: 100%;
  border-radius: .5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-slate-50) / var(--tw-bg-opacity))
}

.dark .report-box-2:before {
  border-color: rgb(var(--color-darkmode-500) / .6);
  background-color: rgb(var(--color-darkmode-600) / .7)
}

.report-box-2 .report-box-2__main-icon {
  height: 2.7rem;
  width: 2.7rem
}

.report-box-2 .report-box-2__indicator {
  display: flex;
  align-items: center;
  padding-left: .375rem;
  padding-right: .25rem
}

.box {
  box-shadow: 0 3px 20px #0000000b;
  position: relative;
  border-radius: .375rem;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.dark .box {
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity))
}

.box {
  box-shadow: 0 3px 5px #0000000b;
  border-radius: .5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-slate-200) / var(--tw-border-opacity))
}

.dark .box {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-darkmode-500) / var(--tw-border-opacity))
}

@media (min-width: 1279px) {
  .report-box .report-box__item:not(:last-child):before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: -7px;
    margin-top: -25px;
    height: 12px;
    width: 13px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-200) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child):before {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-500) / var(--tw-bg-opacity))
  }

  .report-box .report-box__item:not(:last-child):after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: -6px;
    margin-top: -28px;
    height: 14px;
    width: 11px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child):after {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-700) / var(--tw-bg-opacity))
  }

  .report-box .report-box__item:not(:last-child) .report-box__content:before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-right: -7px;
    margin-bottom: -25px;
    height: 12px;
    width: 13px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-200) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child) .report-box__content:before {
    background-color: rgb(var(--color-darkmode-700) / .6)
  }

  .report-box .report-box__item:not(:last-child) .report-box__content:after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-right: -6px;
    margin-bottom: -28px;
    height: 14px;
    width: 11px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-50) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child) .report-box__content:after {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity))
  }
}

.report-box .report-box__item .report-box__item__icon {
  width: 2.2rem;
  height: 2.2rem
}

.report-box .report-box__item .report-box__item__icon svg {
  width: 1.3rem;
  height: 1.3rem
}

.report-box .report-box__item .report-box__item__indicator {
  display: flex;
  align-items: center;
  padding-left: .5rem
}


@media (min-width: 1279px) {
  .report-box .report-box__item:not(:last-child):before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: -7px;
    margin-top: -25px;
    height: 12px;
    width: 13px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-200) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child):before {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-500) / var(--tw-bg-opacity))
  }

  .report-box .report-box__item:not(:last-child):after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: -6px;
    margin-top: -28px;
    height: 14px;
    width: 11px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child):after {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-700) / var(--tw-bg-opacity))
  }

  .report-box .report-box__item:not(:last-child) .report-box__content:before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-right: -7px;
    margin-bottom: -25px;
    height: 12px;
    width: 13px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-200) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child) .report-box__content:before {
    background-color: rgb(var(--color-darkmode-700) / .6)
  }

  .report-box .report-box__item:not(:last-child) .report-box__content:after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-right: -6px;
    margin-bottom: -28px;
    height: 14px;
    width: 11px;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-slate-50) / var(--tw-bg-opacity))
  }

  .dark .report-box .report-box__item:not(:last-child) .report-box__content:after {
    --tw-bg-opacity: 1;
    background-color: rgb(var(--color-darkmode-600) / var(--tw-bg-opacity))
  }
}

:root {
  --color-primary: 0 25 95;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 6 182 212;
  --color-warning: 245 158 11;
  --color-pending: 234 88 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
  --color-slate-50: 248 250 252;
  --color-slate-100: 241 245 249;
  --color-slate-200: 226 232 240;
  --color-slate-300: 203 213 225;
  --color-slate-400: 148 163 184;
  --color-slate-500: 100 116 139;
  --color-slate-600: 71 85 105;
  --color-slate-700: 51 65 85;
  --color-slate-800: 30 41 59;
  --color-slate-900: 15 23 42
}

@-webkit-keyframes intro-x-animation {
  to {
    opacity: 1;
    transform: translate(0)
  }
}

@keyframes intro-x-animation {
  to {
    opacity: 1;
    transform: translate(0)
  }
}

@-webkit-keyframes intro-y-animation {
  to {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes intro-y-animation {
  to {
    opacity: 1;
    transform: translateY(0)
  }
}

/* .container-margin{
  margin-left:290px;
  margin-right: 40px;
} */

/* Styles pour les boutons radio avec la classe "radio-button" */
.radio-button[type="radio"] {
  /* Masquer les boutons radio par défaut */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* Taille des boutons radio personnalisés */
  width: 16px;
  height: 16px;
  /* Style des bordures */
  border: 1px solid #e1e1e2;
  border-radius: 50%;
  /* Position relative pour faciliter le positionnement du label */
  position: relative;
  /* Ajouter de l'espace autour du bouton radio */
  padding: 4px;
  /* Style du bordure supplémentaire pour les boutons non sélectionnés */
  box-shadow: inset 0 0 0 2px white;

  top: .13rem
}

/* Styles pour les boutons radio sélectionnés avec la classe "radio-button" */
.radio-button[type="radio"]:checked {
  /* Couleur de la case sélectionnée */
  background-color: #4338ca;
  /* Style de la bordure pour le bouton sélectionné */
  border-color: #4338ca;

}

/* Styles pour le label du bouton radio avec la classe "radio-button" */
.radio-button[type="radio"]+label {
  /* Position du label */
  position: relative;
  /* Ajouter de l'espace à gauche pour le bouton radio */
  padding-left: 24px;
  /* Style du texte du label */
  font-weight: bold;
}

/* Styles pour le pointeur de la souris au survol des boutons radio avec la classe "radio-button" */
.radio-button[type="radio"]:hover {
  cursor: pointer;
}

.mini-search:focus {
  outline: none !important;
}

.mini-search {
  outline: none !important;
  padding: 0 !important;
  border: 0 !important
}

.input {
  border-color: transparent;
  outline: none;
  box-shadow: none;
}

.input:focus {
  border-color: transparent;
  box-shadow: none;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)
}

#react-select-1-input,
#react-select-2-input,
#react-select-3-input,
#react-select-4-input,
#react-select-5-input,
#react-select-6-input,
#react-select-7-input,
#react-select-8-input,
#react-select-9-input,
#react-select-10-input,
#react-select-11-input,
#react-select-12-input,
#react-select-13-input,
#react-select-14-input,
#react-select-15-input,
#react-select-16-input,
#react-select-17-input,
#react-select-18-input,
#react-select-19-input,
#react-select-20-input,
#react-select-21-input,
#react-select-22-input,
#react-select-23-input,
#react-select-24-input,
#react-select-25-input {
  border: 0 !important;
  border: transparent;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  outline: none !important;
}

.react-datepicker-ignore-onclickoutside {
  border: 0 !important;
}

#mapGLS {
  z-index: 100 !important;
}

@media screen and (max-width: 800px) {

  table.min-w-full {
    overflow-x: auto;
  }

  .responsive-thead {
    display: block;
  }

  table.min-w-full thead {
    display: none;
  }

  table.min-w-full tr {
    padding: 5px;
    display: block;
    margin: .5rem 0 1rem;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, .12), 0 2px 10px 0 rgba(0, 0, 0, .12), 0 0 1px 0 rgba(0, 0, 0, .16);
  }

  table.min-w-full td {
    display: block;
    text-align: right;
    border-bottom: 1px dotted #ccc;
    padding: 0.5rem;
  }

  table.min-w-full td:last-child {
    border-bottom: 0;
  }

  table.min-w-full td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}


@media screen and (max-width: 800px) {
  .catalogue-nav {
    display: none;
  }

  .margin-responsive-nav {
    margin-top: 60px
  }

  .responsive-catalogue-nav {
    display: block !important;
  }
}

.responsive-catalogue-nav {
  display: none;
}

.leaflet-container {
  z-index: 0 !important;
}

#mapGLS {
  z-index: 0 !important;

}

.notification-error {
  background-color: white !important;
  border-left: 3px solid #e74c3c !important;
  color: black !important;
  margin-bottom: 10px;
}

.notification-error:before {
  content: "";
  color: #e74c3c !important
}


.notification-success {
  background-color: white !important;
  border-left: 3px solid #2ecc71 !important;
  color: black !important;
  margin-bottom: 10px;
}

.notification-success:before {
  content: "";
  color: #2ecc71 !important
}



@media screen and (max-width: 800px) {
  .reponsive-actions {
    display: block !important;
  }
}

.reponsive-actions {
  display: none;
}